<template>
	<div class="main-contents">
		<div class="inquiry_wrap">
			<div class="path">Main > 문의하기</div>
			<div class="title_wrap">
            	<h2>문의하기</h2>
          	</div>

			<ul class="inquiry-write">
				<li>
					<div class="title">
						<label for="email">이메일 주소 <span class="icon-required">*</span></label>
					</div>
					<InputComp title="이메일 주소" id="email" v-model="inquiryMattDetail.email" :maxByte="100" :rules="{'required':true}" placeholder="답변 받으실 이메일 주소를 입력해 주세요."/>
            	</li>
				<li>
					<div class="title">
						<label for="subject">제목 <span class="icon-required">*</span></label>
					</div>
					<InputComp title="제목" id="subject" v-model="inquiryMattDetail.title" :maxByte="300" :rules="{'required':true}" placeholder="문의 하실 내용의 제목을 입력해 주세요." />
				</li>
				<li>
					<div class="title">
						<label for="inquiryContent">문의 내용 <span class="icon-required">*</span></label>
					</div>
					<textarea id="inquiryContent" cols="30" rows="10" placeholder="문의내용" v-model="inquiryMattDetail.inquiryCont" style="transform: none;"></textarea>
				</li>
				<li>
					<div class="title">
						<label for="contract">연락처 <span class="icon-required">*</span></label>
					</div>
					<InputComp type="cell" title="연락처" id="contract" v-model="inquiryMattDetail.telNo" :rules="{'required':true}" placeholder="“ - ” 없이 번호만 입력"/>
				</li>
			</ul>

			<div class="text-description">
				<strong class="title">개인정보 수집 및 이용에 대한 동의 내용 </strong>
				① 개인정보 수집 항목 : 이메일, 연락처<br />
				② 수집목적 : 고객식별, 문의 응대, 서비스 품질 향상<br />
				③ 보유 및 이용기간 : 수집 목적이 달성되면 지체없이 모든 개인정보를 파기합니다. 단, 관계법령에서 일정 기간 정보의 보관을 규정한 경우에 한해 분리 보관 후 파기합니다. <br />
				<br />
				※ 위 동의는 거부할 수 있으며, 거부시 해당 문의를 처리할 수 없습니다.
          	</div>

			<div class="inquiry-bottom">
				<div class="agreement"><input type="checkbox" id="agree" v-model="inquiryMattDetail.agreeYn" /> <label for="agree">동의합니다</label> <span class="icon-required">*</span></div>
				<button type="button" class="btn-reg" @click="setInsertInquiryMattDetail()">등록하기</button>
          	</div>
		</div>
	</div>
	
</template>
<script>
export default {
	data() {
		return{
			inquiryMattDetail: {
				email : '',
				title : '',
				inquiryCont : '',
				telNo: '',
				agreeYn : ''
			},
		}
	},

	methods : {
		setInsertInquiryMattDetail(){

			if(this.inquiryMattDetail.agreeYn != true) {
				alert("'개인정보 수집 및 이용동의'는 필수약관 입니다.");
				return false;
			}

			if(!this.vaildationChk()) {
				return false;
			}

			this.$.popup('/man/cts/CTS001P01')
				.then(res=>{
					if(res){
						this.$.httpPost('/api/main/adm/cts/setInsertInquiryMattDetail', this.inquiryMattDetail)
						.then(() => {
							this.movePage();
						}).catch(this.$.httpErrorCommon);
					}
				})
		},

		movePage(div) {
        if(div == 'back') this.$router.go(-1);
        else this.$router.push('/');
        },

		vaildationChk(){
			const validateEmail = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/;
			var emailChk = validateEmail.test(this.inquiryMattDetail.email);

			if(!emailChk){
				alert("이메일 형식이 아닙니다.");
				return false;
			}else if(this.inquiryMattDetail.inquiryCont.trim() == '' || this.inquiryMattDetail.inquiryCont == null){
				alert("[문의내용] 필수 입력사항 입니다.");
				return false;
			}

			return true;
		}
	}
}
</script>